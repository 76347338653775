













































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TyreSize from './TyreSize'

@Component
export default class CustomVehicleTyreList extends Vue {
  @Prop() vehicle
  @Prop() tyres

  isLoading = false
  addTyreItem: TyreSize = new TyreSize()
  addTyreMessage = ''

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    totalItems: 0,
  }

  get headers() {
    return [
      {
        text: this.$t('Dimensions'),
        value: '',
        sortable: false,
      },
      {
        text: this.$t('Commercial Truck'),
        value: '',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Extra load'),
        value: '',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Actions'),
        value: '',
        sortable: false,
        align: 'right',
      },
    ]
  }

  findTyre(tyre) {
    for (let i = 0; i < this.tyres.length; i++) {
      if (tyre.equals(this.tyres[i])) {
        return i
      }
    }
    return -1
  }

  addTyre() {
    this.addTyreMessage = ''
    const tyre = this.addTyreItem.copy()

    if (tyre.valid()) {
      if (this.findTyre(tyre) !== -1) {
        this.addTyreMessage = 'Already exists'
        return
      }
    } else {
      this.addTyreMessage = 'Invalid tyre size'
      return
    }

    this.tyres.push(tyre)
    this.saveTyres()
  }

  removeTyre(tyre) {
    const index = this.findTyre(tyre)
    if (index !== -1) {
      this.tyres.splice(index, 1)
    }
    this.saveTyres()
  }

  saveTyres() {
    this.isLoading = true
    this.$axios
      .post('/v3/custom-vehicles-tyres/' + this.vehicle._id, {
        tyres: this.tyres,
      })
      .then((response) => {
        this.isLoading = false
        this.$emit('save')
      })
      .catch((err) => {
        this.isLoading = false
      })
  }
}
