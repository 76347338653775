













































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import TyreSize from './../../components/custom-vehicle/TyreSize'
import CustomVehicleRimConnector from '../../components/custom-vehicle/CustomVehicleRimConnector.vue'
import CustomVehicleRimList from '../../components/custom-vehicle/CustomVehicleRimList.vue'
import CustomVehicleWheelConnector from '../../components/custom-vehicle/CustomVehicleWheelConnector.vue'
import CustomVehicleWheelList from '../../components/custom-vehicle/CustomVehicleWheelList.vue'
import CustomVehicleTyreList from '../../components/custom-vehicle/CustomVehicleTyreList.vue'
import CustomVehicleVariantConnector from '../../components/custom-vehicle/CustomVehicleVariantConnector.vue'
import CustomVehicleVariantList from '../../components/custom-vehicle/CustomVehicleVariantList.vue'
import DateField from '../../components/inputs/DateField.vue'
import { vxm } from '@/store'

class Vehicle {
  _id: string
  brand: number
  name: string
  variant: string
  fromDate = ''
  toDate = ''
  comment: string
  externalComment: string
  inactive: boolean
  bmwMBrakesDiameterFrom: number
  bmwMBrakesDiameterTo: number
  bmwPerformanceBrakesDiameterFrom: number
  bmwPerformanceBrakesDiameterTo: number
}

@Component({
  components: {
    CustomVehicleVariantList,
    CustomVehicleVariantConnector,
    CustomVehicleRimList,
    CustomVehicleRimConnector,
    CustomVehicleWheelList,
    CustomVehicleWheelConnector,
    CustomVehicleTyreList,
    DateField,
  },
})
export default class CustomVehicleEditor extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  tabs = {
    main: null as string,
    variants: null as string,
    rims: null as string,
    tyres: null as string,
    wheels: null as string,
  }

  errorMessage = ''
  vehicle: Vehicle = new Vehicle()
  variants = []
  rims = []
  tyres = []
  wheels = []
  valid = null
  loading = false

  get isExisting() {
    return !!this.vehicle._id
  }

  get isNew() {
    return !this.vehicle._id
  }

  get isBMW() {
    return this.vehicle && this.vehicle.brand === 16
  }

  get brands() {
    return vxm.vehicles.brands
  }

  mounted() {
    if (this.$route.params.id) {
      this.load(this.$route.params.id)
    }
  }

  reloadVariants() {
    this.$axios.get('/v3/custom-vehicles-mapping/' + this.$route.params.id).then((response) => {
      this.variants = response.data.data
    })
  }

  removeVariants(selection) {
    const keepVariantIds = []
    const keepVariants = []
    this.variants.forEach(function (v, i) {
      if (selection.indexOf(v) === -1) {
        keepVariants[keepVariants.length] = v
        keepVariantIds[keepVariantIds.length] = v._id
      }
    })
    const params = { variants: keepVariantIds, append: false }
    this.$axios.post('/v3/custom-vehicles-mapping/' + this.$route.params.id, params).then((response) => {
      this.variants = keepVariants
    })
  }

  reloadRims() {
    this.$axios.get('/v3/custom-vehicles-rims/' + this.$route.params.id).then((response) => {
      this.rims = response.data.data.products
    })
  }

  removeRims(selected) {
    const keepRims = this.rims.filter((rim) => !selected.includes(rim))
    const keepRimIds = keepRims.map((rim) => rim.product_id)
    const params = { rims: keepRimIds, append: false }
    this.$axios.post('/v3/custom-vehicles-rims/' + this.$route.params.id, params).then((response) => {
      this.rims = keepRims
    })
  }

  reloadWheels() {
    this.$axios.get('/v3/custom-vehicles-wheels/' + this.$route.params.id).then((response) => {
      this.wheels = response.data.data.products
    })
  }

  removeWheels(selected) {
    const keepWheels = this.wheels.filter((wheel) => !selected.includes(wheel))
    const keepWheelIds = keepWheels.map((wheel) => wheel.product_id)
    const params = { wheels: keepWheelIds, append: false }
    this.$axios.post('/v3/custom-vehicles-wheels/' + this.$route.params.id, params).then((response) => {
      this.wheels = keepWheels
    })
  }

  submit() {
    if (!this.$refs.form.validate()) {
      this.tabs.main = '0'
    } else {
      this.save()
    }
  }

  save() {
    this.errorMessage = ''

    const url = this.vehicle._id ? '/v3/custom-vehicles/' + this.vehicle._id : '/v3/custom-vehicles'

    if (!this.vehicle.name) {
      this.errorMessage = 'Name is required'
      return
    }

    this.loading = true

    this.$axios.post(url, this.$data).then((response) => {
      if (this.isNew) {
        this.$router.push(
          {
            name: 'Admin/Vehicles/Edit',
            params: { id: response.data.data.id },
          },
          () => {
            this.load(response.data.data.id)
            this.loading = false
          },
        )
      } else {
        this.$router.push({
          name: 'Admin/Vehicles',
        })
      }
    })
  }

  load(id) {
    this.$axios
      .get('/v3/custom-vehicles/' + id)
      .then((response) => {
        this.vehicle = response.data.data.vehicle
        this.variants = response.data.data.variants || []
        this.rims = response.data.data.rims || []
        this.wheels = response.data.data.wheels || []
        if (response.data.data.tyres) {
          response.data.data.tyres.forEach((v, k) => {
            const t = new TyreSize()
            t.hydrate(v)
            this.tyres.push(t)
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
