












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class CustomVehicleVariantList extends Vue {
  @Prop() variants: Array<any>
  @Prop() enableSelection: boolean
  @Prop() enableConnectedTo: boolean
  @Prop() actions: Array<any>
  @Prop() emptyText: string
  @Prop() loading: boolean

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    totalItems: 0,
  }

  selected = []

  get headers() {
    return [
      {
        text: this.$t('Model'),
        value: 'modelName',
      },
      {
        text: this.$t('Generation'),
        value: 'generation',
      },
      {
        text: this.$t('Chassis'),
        value: 'chassisName',
      },
      {
        text: this.$t('Variant'),
        value: 'variantName',
      },
      this.enableConnectedTo && {
        text: this.$t('Connected to'),
        value: 'customVehicle.name',
      },
    ].filter((i) => !!i)
  }

  get isEmpty() {
    return !this.variants || this.variants.length === 0
  }

  get selectedCount() {
    return this.selected.length
  }

  preformAction(a) {
    this.$emit(a.event, this.selected)
  }
}
