































import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomVehicleRimList from './CustomVehicleRimList.vue'

@Component({
  components: { CustomVehicleRimList },
})
export default class CustomVehicleRimConnector extends Vue {
  searchProductBrand = ''
  searchProductQuery = ''
  searchOnlyUnmapped = false
  products = []
  isLoading = false

  @Prop() vehicle

  get selectedCount() {
    let count = 0
    this.products.forEach(function (v, i) {
      count += v.selected ? 1 : 0
    })
    return count
  }

  search() {
    this.isLoading = true
    let params = 'query=' + encodeURIComponent(this.searchProductBrand + ' ' + this.searchProductQuery)
    if (this.searchOnlyUnmapped) {
      params += '&unmapped=1'
    }
    this.$axios
      .get('/v3/custom-vehicles-rims?' + params)
      .then((response) => {
        // { count, limit, page, products }
        this.products = response.data.data.products.map((item) => ({ ...item, id: item.product_id }))
        this.isLoading = false
      })
      .catch((err) => {
        this.isLoading = false
        this.products = []
      })
  }

  connect(selected) {
    if (!selected.length) return
    const rimIds = selected.map((product) => product.product_id)
    this.$axios
      .post('/v3/custom-vehicles-rims/' + this.vehicle._id, {
        rims: rimIds,
        append: true,
      })
      .then((response) => {
        this.products.forEach((v, i) => {
          if (selected.indexOf(v) !== -1) {
            v.customVehicle = {
              _id: this.vehicle._id,
              name: this.vehicle.name,
            }
          }
        })
        this.$emit('save')
      })
      .catch((err) => {})
  }
}
