
































import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomVehicleVariantList from './CustomVehicleVariantList.vue'

@Component({
  components: { CustomVehicleVariantList },
})
export default class CustomVehicleVariantConnector extends Vue {
  searchModelQuery = ''
  searchPlateQuery = ''
  searchOnlyUnmapped = false
  variants = []
  loading = false
  @Prop() vehicle

  get selectedCount() {
    let count = 0
    this.variants.forEach(function (v, i) {
      count += v.selected ? 1 : 0
    })
    return count
  }

  search() {
    this.loading = true
    let params = 'model=' + encodeURIComponent(this.searchModelQuery || '')
    params += '&brand=' + encodeURIComponent(this.vehicle.brand)
    params += '&licenseplate=' + encodeURIComponent(this.searchPlateQuery || '')
    if (this.searchOnlyUnmapped) {
      params += '&unmapped=1'
    }
    this.$axios
      .get('/v3/custom-vehicles-mapping?' + params)
      .then((response) => {
        this.variants = response.data.data
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
      })
  }

  connect(selected) {
    if (!selected.length) return
    const variantIds = selected.map((product) => product._id)
    this.$axios
      .post('/v3/custom-vehicles-mapping/' + this.vehicle._id, {
        variants: variantIds,
        append: true,
      })
      .then((response) => {
        this.variants = this.variants.map((v) =>
          selected.includes(v)
            ? {
                ...v,
                customVehicle: {
                  _id: this.vehicle._id,
                  name: this.vehicle.name,
                },
              }
            : v,
        )
        this.$emit('save')
      })
      .catch((err) => {})
  }
}
