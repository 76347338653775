import { render, staticRenderFns } from "./CustomVehicleWheelList.vue?vue&type=template&id=1c76dd91&scoped=true&"
import script from "./CustomVehicleWheelList.vue?vue&type=script&lang=ts&"
export * from "./CustomVehicleWheelList.vue?vue&type=script&lang=ts&"
import style0 from "./CustomVehicleWheelList.vue?vue&type=style&index=0&id=1c76dd91&lang=sass&scoped=true&"
import style1 from "./CustomVehicleWheelList.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c76dd91",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VBtn,VCardText,VDataTable,VProgressLinear})
