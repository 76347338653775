export default class TyreSize {
  width: string
  aspectRatio: string
  diameter: string
  isCommercialTruck: boolean
  isExtraLoad: boolean

  format() {
    return this.width + ' / ' + this.aspectRatio + ' - ' + this.diameter
  }

  equals(tyre) {
    return (
      tyre.width === this.width &&
      tyre.aspectRatio === this.aspectRatio &&
      tyre.diameter === this.diameter &&
      tyre.isCommercialTruck === this.isCommercialTruck &&
      tyre.isExtraLoad === this.isExtraLoad
    )
  }

  valid() {
    const pattern = /^\d+(\.\d\d?)?$/
    return (
      this.width &&
      this.aspectRatio &&
      this.diameter &&
      this.width.match(pattern) &&
      this.aspectRatio.match(pattern) &&
      this.diameter.match(pattern)
    )
  }

  copy() {
    const copy = new TyreSize()
    copy.width = this.width
    copy.aspectRatio = this.aspectRatio
    copy.diameter = this.diameter
    copy.isCommercialTruck = this.isCommercialTruck
    copy.isExtraLoad = this.isExtraLoad
    return copy
  }

  hydrate(data) {
    this.width = data.width
    this.aspectRatio = data.aspectRatio
    this.diameter = data.diameter
    this.isCommercialTruck = data.isCommercialTruck
    this.isExtraLoad = data.isExtraLoad
  }
}
