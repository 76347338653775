









































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class CustomVehicleRimList extends Vue {
  @Prop() products: Array<any>
  @Prop() enableSelection: boolean
  @Prop() enableConnectedTo: boolean
  @Prop() actions: Array<any>
  @Prop() emptyText: string
  @Prop() loading: boolean

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    totalItems: 0,
  }

  selected = []

  get headers() {
    return [
      {
        text: this.$t('Supplier art.no'),
        value: 'supplier_product_id',
      },
      {
        text: this.$t('Brand'),
        value: 'brand_name',
      },
      {
        text: this.$t('Model'),
        value: 'model_name',
      },
      {
        text: this.$t('Details'),
        value: 'details',
        sortable: false,
      },
      {
        text: this.$t('Stock qty.'),
        value: 'stock',
      },
      this.enableConnectedTo && {
        text: this.$t('Connected to'),
        value: 'customVehicleName',
      },
    ].filter((i) => !!i)
  }

  get isEmpty() {
    return !this.products || this.products.length === 0
  }

  get selectedCount() {
    return this.selected.length
  }

  preformAction(a) {
    this.$emit(a.event, this.selected)
  }

  @Watch('products')
  onProductsChange() {
    this.selected = this.selected.filter((product) => this.products.includes(product))
  }

  productAttributes(product) {
    let summary = product.summary
    summary = summary.replace(product.brand_name, '')
    summary = summary.replace(product.model_name, '')
    summary += product.rim_type === 2 ? ' (A)' : ' (S)'
    summary = summary.trim()
    return summary
  }
}
